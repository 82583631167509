import * as React from "react";
import Layout from "../../components/Layout";
import ImageScroller from "../../ui-blocks/ImageScroller";
import PrevNextNavFooter from "../../ui-blocks/PrevNextNavFooter/PrevNextNavFooter";

import Toyota_Tagline_Incorrect_Usage_1_svg from "../../images/4. Tagline/Toyota_Tagline_Incorrect_Usage_1.svg";
import Toyota_Tagline_Incorrect_Usage_2_svg from "../../images/4. Tagline/Toyota_Tagline_Incorrect_Usage_2.svg";
import Toyota_Tagline_Incorrect_Usage_3_svg from "../../images/4. Tagline/Toyota_Tagline_Incorrect_Usage_3.svg";
import Toyota_Tagline_Incorrect_Usage_4_svg from "../../images/4. Tagline/Toyota_Tagline_Incorrect_Usage_4.svg";
import Toyota_Tagline_Incorrect_Usage_5_svg from "../../images/4. Tagline/Toyota_Tagline_Incorrect_Usage_5.svg";
import Toyota_Tagline_Incorrect_Usage_6_svg from "../../images/4. Tagline/Toyota_Tagline_Incorrect_Usage_6.svg";

const Tagline = (props) => {
  return (
    <Layout>
      <div
        className="page-header image-header"
        id="overview"
        style={{ backgroundColor: "rgb(255, 255, 255)", height: "650px" }}
      >
        <div
          className="content-flex"
          style={{
            backgroundColor: "rgb(154,159,163)",
            top: "",
            textAlign: "left",
            height: "650px",
          }}
        >
          <h1
            className="copy"
            style={{ color: "#fff", top: "", textAlign: "left" }}
          >
            <em>
              <strong>Let's Go Places</strong>
            </em>{" "}
            is the expression that captures the progressiveness and optimism
            that Toyota stands for today. It speaks to our ambition to move
            forward together and positions the brand in a way that's relevant
            and inclusive for all New Zealanders. whatever their adventures may
            be and wherever they may take them.
          </h1>
        </div>

        {/* <div className="background" style={{backgroundColor:'#fff'}}>
                    <picture>
                        <source media="(max-width:767px)" srcSet="/brandguidelines/imgix/tagline/section-header/tagline_header_mobile.jpg"/>
                        <source media="(min-width:768px) and (max-width:1024px)"  srcSet="/brandguidelines/imgix/tagline/section-header/tagline_header_tablet.jpg"/>
                        <source media="(min-width:1900px)" srcSet="/brandguidelines/imgix/tagline/section-header/tagline_header.jpg"/>
                            <img alt="" src="/brandguidelines/imgix/tagline/section-header/tagline_header_desktop.jpg" alt="Campers near an open Toyota vehicle in nature setting"/>
                    </picture>
                </div> */}
      </div>

      <section
        className="component Section"
        data-index="0"
        data-navindex="0"
        id="overview"
      >
        <div
          className="blog-content darker-background"
          style={{ paddingTop: "80px" }}
        >
          <div className="container onecolumn">
            <div className="copy">
              Use our tagline{" "}
              <em>
                <strong>Let's Go Places</strong>
              </em>{" "}
              with intention and integrity. Follow the guidelines below for
              correct usage whether applied as a headline, a logo or as the
              concluding statement in body text.
            </div>
          </div>
        </div>
      </section>

      <section
        className="component Section darker-background"
        id="used-as-headline"
      >
        <div className="section-header">
          <div className="container">
            <h2 className="ui header">Used as Headline</h2>
          </div>
        </div>

        <div className="blog-content" data-compid="used-as-headline-0">
          <div className="container onecolumn" id="">
            <h3 className="title">Our primary brand message</h3>
            <div className="copy spaced-p">
              When used as a headline,{" "}
              <em>
                <strong>Let's Go Places</strong>
              </em>{" "}
              artwork should be used.
            </div>

            <div className="copy spaced-p">
              <em>
                <strong>Let's Go Places</strong>
              </em>{" "}
              may be used stacked sa two or three lines, in which case it should
              be typeset in title case and Semibold italic and should not
              include a full stop. Do not use in combination with the{" "}
              <em>
                <strong>Let's Go Places</strong>
              </em>{" "}
              logo. The tagline, however, may be repeated when used at the end
              of body text.
            </div>
          </div>
        </div>
        <div
          className="image"
          style={{ background: "white", height: "400px" }}
          data-compid="used-as-headline-1"
        >
          <div className="full-width">
            <div className="image">
              <div
                style={{ height: "100%" }}
                className="lazyload-placeholder"
              ></div>
            </div>
          </div>
        </div>
      </section>

      <section className="component Section" id="used-as-logo">
        <div className="section-header darker-background" data-tag="absent">
          <div className="container">
            <h2 className="ui header">Let's Go Places Logo</h2>
          </div>
        </div>
        <div
          className="blog-content darker-background"
          data-compid="used-as-logo-0"
        >
          <div className="container onecolumn" id="">
            <div className="copy spaced-p">
              There are three configurations of the{" "}
              <em>
                <strong>Let's Go Places</strong>
              </em>{" "}
              logo: horizontal stacked, horizontal and vertical. Use the
              arrangement most appropriate to the format of the communication
              and the design layout. Go to the logo section for more direction.
            </div>
          </div>
        </div>

        <div className="image" style={{ background: "white", height: "500px" }}>
          <div className="full-width">
            <div className="image">
              <div
                style={{ height: "100%" }}
                className="lazyload-placeholder"
              ></div>
            </div>
          </div>
        </div>
        {/* <div className="tabs" style={{background:'#fafafa'}} data-compid="used-as-logo-1">
                    <div className="full-width">
                        <div className="tab-scroll">
                            <div className="ui pointing secondary menu">
                                <a className="active item">Horizontal Stacked</a>
                                <a className="item">Horizontal</a>
                                <a className="item">vertical</a>
                            </div>
                            <div className="ui bottom attached segment active tab">
                                <div className="ui segment active tab image-tab">
                                    <div className="image">
                                        <div style={{height:'100%'}} className="lazyload-placeholder"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="ui bottom attached segment tab">
                                <div className="ui segment active tab image-tab">
                                    <div className="image">
                                        <div style={{height:'100%'}} className="lazyload-placeholder"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="ui bottom attached segment tab">
                                <div className="ui segment active tab image-tab">
                                    <div className="image">
                                        <div style={{height:'100%'}} className="lazyload-placeholder"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
        {/* <div className="image" style={{background:'#fafafa'}} data-compid="used-as-logo-2">
                    <div className="full-width">
                        <div className="image">
                            <div style={{height:'100%'}} className="lazyload-placeholder"></div>
                        </div>
                    </div>
                </div>
             */}
      </section>

      <section className="component Section" id="used-in-body-text">
        <div className="section-header darker-background">
          <div className="container">
            <h2 className="ui header">Used in Body Text</h2>
          </div>
        </div>
        <div className="blog-content darker-background">
          <div className="container onecolumn" id="">
            <div className="copy">
              <em>
                <strong>Let's Go Places</strong>
              </em>{" "}
              can be typeset within the body copy or at the end. It shoiuld
              follow the same type size and colour as the body copy.But to
              ensure it stands out, it should be set in the title case and
              Semibold Italic.
            </div>
          </div>
        </div>

        {/* <div style={{background:'#fafafa', height: '400px'}}>
                    <div className="full-width">
                        <div className="image">
                            <div style={{height:'100%'}} className="lazyload-placeholder"></div>
                        </div>
                    </div>
                </div> */}

        {/* <div className="image" style={{background:'#fafafa'}} data-compid="used-in-body-text-1">
                    <div className="full-width">
                        <div className="image">
                            <div style={{height:'100%'}} className="lazyload-placeholder"></div>
                        </div>
                    </div>
                </div> */}
      </section>

      <section className="component Section" id="incorrect-use">
        <div className="section-header darker-background">
          <div className="container">
            <h2 className="ui header">Incorrect Usage</h2>
          </div>
        </div>
        <div className="blog-content darker-background">
          <div className="container onecolumn" id="">
            <div className="copy spaced-p">
              Both for building brand awareness and trademark protection, it’s
              crucial to implement the consistent and correct usage of{" "}
              <em>Let's Go Places</em>. Use our tagline with intention and
              integrity. In general, do not alter it in any way or use it
              incorrectly. The examples below illustrate possible misuses, but
              they are not exhaustive in possibilities.
            </div>
          </div>
        </div>

        <ImageScroller
          style={{ backgroundColor: "white", paddingTop: "0" }}
          images={[
            {
              image: {
                svg: Toyota_Tagline_Incorrect_Usage_1_svg,
                style: { height: "570px", width: "570px" },
              },
              caption: {
                copy: "Lorem ipsum",
                style: { marginTop: "-100px" },
              },
            },
            {
              image: {
                svg: Toyota_Tagline_Incorrect_Usage_2_svg,
                style: { height: "570px", width: "570px" },
              },
              caption: {
                copy: "Lorem ipsum",
                style: { marginTop: "-100px" },
              },
            },
            {
              image: {
                svg: Toyota_Tagline_Incorrect_Usage_3_svg,
                style: { height: "570px", width: "570px" },
              },
              caption: {
                copy: "Lorem ipsum",
                style: { marginTop: "-100px" },
              },
            },
            {
              image: {
                svg: Toyota_Tagline_Incorrect_Usage_4_svg,
                style: { height: "570px", width: "570px" },
              },
              caption: {
                copy: "Lorem ipsum",
                style: { marginTop: "-100px" },
              },
            },
            {
              image: {
                svg: Toyota_Tagline_Incorrect_Usage_5_svg,
                style: { height: "570px", width: "570px" },
              },
              caption: {
                copy: "Lorem ipsum",
                style: { marginTop: "-100px" },
              },
            },
            {
              image: {
                svg: Toyota_Tagline_Incorrect_Usage_6_svg,
                style: { height: "570px", width: "570px" },
              },
              caption: {
                copy: "Lorem ipsum",
                style: { marginTop: "-100px" },
              },
            },
          ]}
        ></ImageScroller>
      </section>

      <PrevNextNavFooter
        previousText="Previous"
        previousHeader="Logo"
        previousUrl="/brandguidelines/logo"
        nextText="Next"
        nextHeader="Typography"
        nextUrl="/brandguidelines/typography"
      />
    </Layout>
  );
};
export default Tagline;
